<template>
  <CyModal
    :header-title="$t('formTitle')"
    :loading="loading"
    :action-btn-func="isDeletingScheduling ? deleteScheduling : submit"
    :action-btn-text="isDeletingScheduling ? 'Delete Schedule' : undefined"
    :action-btn-color="isDeletingScheduling ? 'error' : undefined"
    :action-btn-disabled="!canSave"
    :cancel-btn-text="$t('forms.btnClose')"
    :cancel-btn-func="close"
    modal-type="update"
    no-click-animation>
    <CyAlert
      theme="error"
      :content="errors"/>

    <p class="text-left">
      {{ $t('formDescription') }}
      <a
        class="cy-link"
        :href="$docLinks.project.environments"
        target="_blank">
        {{ $t('learnMore') }}
      </a>
    </p>

    <v-switch
      v-model="isSchedulingEnabled"
      color="secondary">
      <template #label>
        <span class="pr-2">{{ $t('enableScheduling') }}</span>
        <span
          class="font-weight-bold d-flex align-center">
          <CyAvatar
            :item="environment"
            class="mr-1"
            sm/>
          {{ environment.canonical }}
        </span>
      </template>
    </v-switch>

    <template v-if="isSchedulingEnabled">
      <v-col>
        <v-select
          v-model="$v.credential.$model"
          :items="credentials"
          :loading="fetchInProgress"
          :label="$t('Credential')"
          :error-messages="credentialsErrors"
          item-text="name"
          return-object
          required
          class="required-field credentials"
          @blur="$v.credential.$touch()">
          <template #selection="data">
            <v-avatar
              :key="data.item.name"
              class="mx-2"
              size="30">
              <CyIconCredential :type="data.item.type"/>
            </v-avatar>
            <div class="black--text">
              {{ data.item.name }}
            </div>
          </template>
          <template #item="data">
            <v-list-item-avatar>
              <CyIconCredential :type="data.item.type"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <CyBtnToggle
          v-model="scheduleType"
          class="text-left"
          :items="$static.scheduleTypes"
          :label="$t('scheduleType')"/>
      </v-col>
      <v-col v-if="isScheduleTypeSelected.dt">
        <v-row>
          <v-col>
            <span
              class="font-weight-black subheading">
              {{ $t('titleStartSchedule') }}
            </span>
            <v-divider/>
            <CyInputsCronPicker
              ref="startCronPicker"
              v-model="startCron"
              class="mt-2"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span
              class="font-weight-black subheading">
              {{ $t('titleEndSchedule') }}
            </span>
            <v-divider/>
            <CyInputsCronPicker
              ref="endCronPicker"
              v-model="endCron"
              class="mt-2"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="isScheduleTypeSelected.cron">
        <v-row>
          <v-col>
            <v-text-field
              v-model="$v.startCron.$model"
              :label="$t('fieldStartCron')"
              :error-messages="startCronErrors"
              hint="m h dom mon dow"
              prepend-icon="today"
              required
              class="required-field"
              @blur="$v.startCron.$touch()"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="$v.endCron.$model"
              :label="$t('fieldEndCron')"
              :error-messages="endCronErrors"
              hint="m h dom mon dow"
              prepend-icon="event"
              required
              class="required-field"
              @blur="$v.endCron.$touch()"/>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </CyModal>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CyBtnToggle from '@/components/btn-toggle.vue'
import CyInputsCronPicker from '@/components/inputs/cron-picker.vue'
import pipelineConfig from 'static/pipelines/start-stop.yml'
import * as yaml from 'js-yaml'
import { required } from 'vuelidate/lib/validators'

const ENV_REGEX = '\\(\\$\\s*environment\\s*\\$\\)'
const ORG_REGEX = '\\(\\$\\s*organization_canonical\\s*\\$\\)'
const PROJECT_REGEX = '\\(\\$\\s*project\\s*\\$\\)'
const CREDENTIAL_PATH_REGEX = '\\(\\$\\s*credential_path\\s*\\$\\)'
const STARTCRON_REGEX = '\\(\\$\\s*startcron\\s*\\$\\)'
const ENDCRON_REGEX = '\\(\\$\\s*endcron\\s*\\$\\)'

export default {
  name: 'CyFormsStartStop',
  components: {
    CyInputsCronPicker,
    CyBtnToggle,
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    environment: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    credential: { required },
    startCron: { required },
    endCron: { required },
  },
  data: ({ edit }) => ({
    isSchedulingEnabled: edit,
    scheduleType: 0,
    loading: false,
    startCron: '',
    endCron: '',
    credential: null,
    errorsFromAPI: undefined,
  }),
  computed: {
    ...mapState({
      credentials: (state) => state.organization.available.credentials,
      fetchInProgress: (state) => state.organization.fetchInProgress.credentials,
      pipelineErrors: (state) => state.organization.project.pipeline.errors.pipeline,
    }),
    $static () {
      return {
        scheduleTypes: [
          {
            key: 'dt',
            text: this.$t('btnDateTime'),
            variant: 'secondary',
          },
          {
            key: 'cron',
            text: this.$t('btnCron'),
            variant: 'secondary',
          },
        ],
      }
    },
    type () {
      return this.$static.scheduleTypes[this.scheduleType].key
    },
    credentialsErrors () {
      const errors = []
      const { $dirty, required } = this.$v.credential
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    startCronErrors () {
      const errors = []
      const { $dirty, required } = this.$v.startCron
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    endCronErrors () {
      const errors = []
      const { $dirty, required } = this.$v.endCron
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    errors () {
      return _.union(this.errorsFromAPI, this.pipelineErrors)
    },
    isScheduleTypeSelected () {
      return _.fromPairs(this.$static.scheduleTypes.map(({ key }, index) => ([key, this.scheduleType === index])))
    },
    isDeletingScheduling () {
      return this.edit && !this.isSchedulingEnabled
    },
    canSave () {
      return !this.loading && (this.isDeletingScheduling || !this.$v.$invalid)
    },
  },
  watch: {
    type () {
      this.startCron = '* * * * *'
      this.endCron = '* * * * *'
      this.$v.$reset()
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'credentials', extraParams: ['aws'] })
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project/pipeline', [
      'DELETE_PIPELINE',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
    ]),
    async submit () {
      this.CLEAR_PROJ_ERRORS()
      this.loading = true
      const success = this.edit
        ? await this.updatePipeline()
        : await this.createPipeline()
      if (success) {
        this.$emit('updated')
        this.close()
      }
      this.loading = false
    },
    replaceAllVariables (baseConfig) {
      const variables = [
        {
          name: ENV_REGEX,
          value: this.environment.canonical,
        },
        {
          name: ORG_REGEX,
          value: this.orgCanonical,
        },
        {
          name: PROJECT_REGEX,
          value: this.projectCanonical,
        },
        {
          name: CREDENTIAL_PATH_REGEX,
          value: this.credential.path,
        },
        {
          name: STARTCRON_REGEX,
          value: this.startCron,
        },
        {
          name: ENDCRON_REGEX,
          value: this.endCron,
        },
      ]
      let config = baseConfig
      for (const variable of variables) {
        const replaceRegex = new RegExp(variable.name, 'g')
        config = config.replace(replaceRegex, variable.value)
      }
      return config
    },
    async createPipeline () {
      const pipeline = this.replaceAllVariables(pipelineConfig)
      const pipelineName = `start-stop-${this.projectCanonical}-${this.environment.canonical}`
      const config = JSON.stringify(yaml.load(pipeline))
      const newPipeline = { pipeline_name: pipelineName, passed_config: config, environment: this.environment }
      const { errors: createErrors } = await this.$cycloid.ydAPI.createPipeline(this.orgCanonical, this.projectCanonical, newPipeline) || {}
      if (createErrors) {
        this.errorsFromAPI = createErrors
        return false
      }
      const { errors: unpauseErrors } = await this.$cycloid.ydAPI.unpausePipeline(this.orgCanonical, this.projectCanonical, pipelineName) || {}
      if (unpauseErrors) {
        this.errorsFromAPI = unpauseErrors
        return false
      }
      return true
    },
    async updatePipeline () {
      const pipeline = this.replaceAllVariables(pipelineConfig)
      const pipelineName = `start-stop-${this.projectCanonical}-${this.environment.canonical}`
      const pipelineToUpdate = { passed_config: JSON.stringify(yaml.load(pipeline)) }

      const { errors } = await this.$cycloid.ydAPI.updatePipeline(this.orgCanonical, this.projectCanonical, pipelineName, pipelineToUpdate) || {}
      if (errors) this.errorsFromAPI = errors
      return _.isEmpty(errors)
    },
    async deleteScheduling () {
      const { projectCanonical, environment: { canonical: envCanonical } } = this
      const pipelineCanonical = `start-stop-${projectCanonical}-${envCanonical}`

      this.loading = true
      await this.DELETE_PIPELINE({ projectCanonical, pipelineCanonical })
      this.loading = false

      if (_.isEmpty(this.pipelineErrors)) {
        this.$emit('updated')
        this.close()
      }
    },
    close () {
      this.$resetData()
      this.$v.$reset()
      this.$emit('close')
    },
  },
  i18n: {
    messages: {
      en: {
        btnConfigStartStop: 'Configure Start/Stop',
        btnCron: 'Cron',
        btnDateTime: 'Date/Time',
        enableScheduling: 'Enable scheduling for',
        fieldEndCron: 'End cron expression',
        fieldStartCron: 'Start cron expression',
        formDescription: 'Reduce costs by scheduling your environment up time. Automatically start and stop instances on your cloud provider.',
        formTitle: 'Scheduling options',
        scheduleType: 'Schedule type',
        serverError: 'Error while creating the start/stop configuration',
        titleEndSchedule: 'End schedule',
        titleStartSchedule: 'Start schedule',
      },
      es: {
        btnConfigStartStop: 'Configurar Inicio/Parada',
        btnCron: 'Cron',
        btnDateTime: 'Hora/Fecha',
        enableScheduling: 'Habilitar la programación para',
        fieldEndCron: 'Expresión cron de parada',
        fieldStartCron: 'Expresión cron de inicio',
        formDescription: 'Reduzca los costos al programar el tiempo de funcionamiento de su entorno. Inicie y detenga automáticamente las instancias en su proveedor de nube.',
        formTitle: 'Opciones de programación',
        scheduleType: 'Tipo de horario',
        serverError: 'Error al crear la configuración de inicio/parada',
        titleEndSchedule: 'Programación de parada',
        titleStartSchedule: 'Programación de inicio',
      },
      fr: {
        btnConfigStartStop: 'Configurer Démarrage/Arrêt',
        btnCron: 'Cron',
        btnDateTime: 'Date/Heure',
        enableScheduling: 'Activer la planification pour',
        fieldEndCron: `Expression cron d'arrêt`,
        fieldStartCron: 'Expression cron de démarrage',
        formDescription: `Réduisez les coûts en programmant votre temps d'environnement.Démarrez et arrêtez automatiquement les instances sur votre fournisseur de cloud.`,
        formTitle: 'Options de planification',
        scheduleType: 'Type de planification',
        serverError: 'Erreur lors de la création de la configuration de démarrage/arrêt',
        titleEndSchedule: `Planning d'arrêt`,
        titleStartSchedule: 'Planning de démarrage',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-card {
    background-color: transparent;

    &__title {
      padding: 0;
    }

    &__text,
    &__actions {
      background-color: white;
    }

    .subheading {
      font-size: $font-size-lg;
    }
  }

  .auto-height {
    height: auto;
    min-height: 36px;
  }
</style>
