var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('span',{staticClass:"cy-btn-toggle__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-btn-toggle',_vm._b({staticClass:"cy-btn-toggle__wrapper",attrs:{"mandatory":_vm.mandatory},on:{"change":function($event){!_vm.$attrs.readonly && _vm.$emit('input', $event)}}},'v-btn-toggle',_vm.$attrs,false),_vm._l((_vm.items),function(ref,index){
var key = ref.key;
var icon = ref.icon;
var image = ref.image;
var text = ref.text;
var theme = ref.theme; if ( theme === void 0 ) theme = _vm.activeTheme;
var value = ref.value;
var disabled = ref.disabled;
var tooltip = ref.tooltip;
var isLoading = ref.isLoading; if ( isLoading === void 0 ) isLoading = false;
var isInlineLoader = ref.isInlineLoader; if ( isInlineLoader === void 0 ) isInlineLoader = false;
return _c('CyTooltip',{key:key,attrs:{"disabled":!tooltip,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyButton',_vm._g({class:['cy-btn-toggle', {
            'cy-btn-toggle--inactive': _vm.getValue(value, index) !== _vm.$attrs.value,
            'cy-btn-toggle--readonly': _vm.$attrs.readonly,
          }],attrs:{"disabled":disabled || (_vm.$attrs.readonly && _vm.getValue(value, index) !== _vm.$attrs.value),"theme":theme,"loading":isLoading,"loading-text":isInlineLoader ? text : '',"value":_vm.getValue(value, index),"variant":"secondary"}},on),[(_vm.isSvg(image))?_c(image,{tag:"component",class:['cy-btn-toggle__image' ].concat( _vm.getImageClasses({ key: key }))}):(image)?_c('img',{class:['cy-btn-toggle__image' ].concat( _vm.getImageClasses({ key: key })),attrs:{"alt":(key + " image"),"src":image}}):_vm._e(),(icon)?_c('v-icon',{class:['cy-btn-toggle__icon' ].concat( _vm.getIconClasses({ key: key }))},[_vm._v(" "+_vm._s(icon)+" ")]):_vm._e(),(!_vm.onlyShowIcons)?_c('span',{class:['cy-btn-toggle__text', {
              'cy-btn-toggle__text--no-margin': _vm.onlyShowText,
            }],domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(text))}}):_vm._e()],1)]}}],null,true)},[_vm._v(" "+_vm._s(tooltip)+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }